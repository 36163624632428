.app {
  background-color: white;
}

.receipt-container {
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .receipt-container h3 {
    font-size: 1.5rem;
  }

  .receipt-container h4 {
    font-size: 1.2rem;
  }

  .receipt-container h5 {
    font-size: 1rem;
  }

  .receipt-container .payment-details {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .receipt-container .payment-details > div:last-child {
    margin-top: 1rem;
    text-align: left !important;
  }

  /* Ensure right-aligned content stays that way on mobile */
  .receipt-container .col-md-6.text-right {
    text-align: right !important;
  }

  /* Make sure QR code stays centered */
  .receipt-container .col-md-4.text-center > div {
    margin: 0 auto;
  }
}
